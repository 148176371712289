import React, {  useState } from "react";
import PreTranslation from "components/PreTranslation/PreTranslation";
import SEO from "components/seo";
import ToolFaq from "components/QualityTools/ToolFaq";
import { PreTranslationToolFaqData } from "components/FAQ/config";
import Layout from "components/layout";
import Loader from "components/loader";

const Result = () => {
  return (
    <Layout>
      <SEO
        title="Pre-Translation Toolkit"
        description="Access the Tomedes Pre-Translation Toolkit to streamline translation preparation with a complete report from multiple AI tools, including topic and language detection, glossary, and context analysis, ensuring high-quality and accurate translations."
        slug="/tools/pre-translation"
      />
      <PreTranslation />
      <ToolFaq QaToolFaqData={PreTranslationToolFaqData} />
    </Layout>
  );
};
export default Result;
